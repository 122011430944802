import React from "react";
import "./HeroSection.scss";
import Slide from "react-reveal/Slide";

const HeroSection = () => {
  return (
    <div className="os-hero-container">
      <div className="hero-img"></div>
      <div className="hero-text-con d-flex flex-row">
        <div className="waw-headline flex-55">
            <p className="p1">OUR</p>
            <p className="p2">SERVICES</p>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
